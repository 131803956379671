import React, { useContext, useState } from "react"
import { useEffect } from "react"
import { Account, Exact, GetAccountQuery, useGetAccountLazyQuery, useGetAccountQuery, User } from "../hooks/accounts"
import { AuthStatus, useAuth } from "./AuthContext"
import { useRouter } from "next/router"
import { NovuProvider } from "@novu/notification-center"

import { ApolloQueryResult, LazyQueryExecFunction } from '@apollo/client';


interface IAccountContext {
  account: Account | null,
  user: User | null,
  loading: boolean,
  refetch?: (variables?: Partial<Exact<{ [key: string]: never; }>> | undefined) => Promise<ApolloQueryResult<GetAccountQuery>>,
  getAccount?: LazyQueryExecFunction<GetAccountQuery, Exact<{ [key: string]: never; }>>
}

const defaultState: IAccountContext = {
  account: null,
  user: null,
  loading: true,
}

const AccountContext = React.createContext(defaultState)

type Props = {
  children?: React.ReactNode
}

const AccountProvider = ({ children }: Props) => {
  const { status, refreshToken } = useAuth()
  const router = useRouter()

  const [account, setAccount] = useState<Account | null>(null)
  const [user, setUser] = useState<User | null>(null)

  const [getAccount, { loading, error, refetch, startPolling, stopPolling }] = useGetAccountLazyQuery({
    onCompleted: (data) => {
      if (!data.getAccount || !data.getUser) {
        startPolling(200)
      } else {
        stopPolling()
      }
      if (data.getAccount) {
        setAccount(data.getAccount)
      }
      if (data.getUser) {
        setUser(data.getUser)
      }
    },
    onError: (err) => {
      console.error(err)
    },
    fetchPolicy: 'no-cache'
  })


  useEffect(() => {
    if (user?.role) refreshToken()
  }, [user?.role])

  useEffect(() => {
    switch (status) {
      case AuthStatus.SignedOut:
        setAccount(null)
        setUser(null)
        break;
      case AuthStatus.SignedIn:
        getAccount()
        break;
    }
  }, [status, router.pathname])

  const state: IAccountContext = {
    account,
    getAccount,
    user,
    loading,
    refetch
  }

  return (
    <AccountContext.Provider value={state}>
      <NovuProvider
        subscriberId={user?.id ? user.id : ''}
        applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_ID!}
      >
        {children}
      </NovuProvider>
    </AccountContext.Provider>
  )

}

export const useAccount = () => useContext(AccountContext)

export default AccountProvider