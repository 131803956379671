import '../styles/globals.css'
import type { AppProps } from 'next/app'
import AuthProvider, { AuthStatus, useAuth } from '../contexts/AuthContext'
import ApiProvider from '../contexts/ApiContext'
import { PrescribeProvider } from '../contexts/PrescribeContext'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import '../styles/nprogress.css'
import { Toaster } from 'react-hot-toast';
import { Inter } from "next/font/google"
import AccountProvider from '../contexts/AccountContext';
import { HighlightInit } from '@highlight-run/next/client'
import { ErrorBoundary } from '@highlight-run/next/client';

export const inter = Inter({
  subsets: ['latin'],
})

function Children({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(true)

  const { user, status } = useAuth()

  useEffect(() => {
    if (status == AuthStatus.SignedOut || status == AuthStatus.SignedIn) {
      setLoading(false)
    }
  }, [status])

  if (loading) return null

  return (
    <div className={inter.className}>
      <PrescribeProvider>
        <Component {...pageProps} />
        <Toaster
          position='top-center'
        />
      </PrescribeProvider>
    </div>
  )
}

function MyApp(props: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleStart = (url: string, { shallow }: { shallow: boolean }) => {
      if (shallow) return
      NProgress.configure({ showSpinner: false })
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])


  return (
    <>
      <HighlightInit
        projectId={process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
        environment={process.env.NEXT_PUBLIC_ENV}
        excludedHostnames={['localhost']}
        // tracingOrigins={['api.nexusmed.cloud']}
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: []
        }}
      />
      <ErrorBoundary>
        <AuthProvider>
          <ApiProvider>
            <AccountProvider>
              <Children {...props} />
            </AccountProvider>
          </ApiProvider>
        </AuthProvider>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
