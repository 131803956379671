import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { AuthOptions, AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { RetryLink } from "@apollo/client/link/retry";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";

import { AuthStatus, useAuth } from "./AuthContext";

/* Region */
const region: string = process.env.NEXT_PUBLIC_REGION!

/**
 * Auth
 */
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: () =>
    (Auth.currentSession() as Promise<CognitoUserSession>)
      .then(sess => sess.getIdToken())
      .then(idtoken => idtoken.getJwtToken())
      .catch(() => { return '' })
}

const apiUrl = (path: string) => `${process.env.NEXT_PUBLIC_API}${path}/graphql`


/**
 * Accounts
 */
const accountsUrl: string = apiUrl('/accounts')
const accountsApiLink = new HttpLink({ uri: accountsUrl })
const accountsLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: accountsUrl, region, auth }),
  accountsApiLink
])

/**
 * Consult
 */
const consultUrl = apiUrl('/consult')
const consultApiLink = new HttpLink({ uri: consultUrl })
const consultLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: consultUrl, region, auth }),
  consultApiLink
])

/**
 * Products
 */
const productsUrl = apiUrl('/products')
const productsApiLink = new HttpLink({ uri: productsUrl })
const productsLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: productsUrl, region, auth }),
  productsApiLink
])


/**
 * Prescribe
 */

/**
 * Shipping
 */
const shippingUrl = apiUrl('/shipping')
const shippingApiLink = new HttpLink({ uri: shippingUrl })
const shippingLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: shippingUrl, region, auth }),
  shippingApiLink
])

/**
 * Patients
 */
const patientsUrl = apiUrl('/patients')
const patientsApiLink = new HttpLink({ uri: patientsUrl })
const patientsLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: patientsUrl, region, auth }),
  patientsApiLink
])

/**
 * Payments
 */
const paymentsUrl = apiUrl('/payments')
const paymentsApiLink = new HttpLink({ uri: paymentsUrl })
const paymentsLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: paymentsUrl, region, auth }),
  paymentsApiLink
])

/**
 * Orders
 */
const ordersUrl = apiUrl('/orders')
const ordersApiLink = new HttpLink({ uri: ordersUrl })
const ordersLink = ApolloLink.from([
  new RetryLink(),
  createAuthLink({ url: ordersUrl, region, auth }),
  ordersApiLink
])


/**
 * Client
 */
// export const client = new ApolloClient({
//   link: ApolloLink.split(
//     operation => operation.getContext().consult,
//     consultLink,
//     ApolloLink.split(
//       operation => operation.getContext().prescribe,
//       rxLink,
//       ApolloLink.split(
//         operation => operation.getContext().shipping,
//         shippingLink,
//         ApolloLink.split(
//           operation => operation.getContext().patients,
//           patientsLink,
//           ApolloLink.split(
//             operation => operation.getContext().products,
//             productsLink,
//             ApolloLink.split(
//               operation => operation.getContext().payments,
//               paymentsLink,
//               ApolloLink.split(
//                 operation => operation.getContext().orders,
//                 ordersLink,
//                 accountsLink
//               )
//             ),
//           )
//         )
//       )
//     )
//   ),
//   cache: new InMemoryCache({
//     possibleTypes: {
//       IAccount: ["Clinic", "Pharmacy", "Unknown", "Individual"],
//       IProduct: ["VirtualMedicinalProduct", "ActualMedicinalProduct"],
//       IProductPack: ["VirtualMedicinalProductPack", "ActualMedicinalProductPack"],
//       IConsultation: ["AsynchronousConsultation"],
//       IMedicinalIngredient: ["Excipient", "ActivePharmaceuticalIngredient"],
//       IPayment: ["PrescriptionPayment"]
//     },
//     typePolicies: {
//       Query: {
//         fields: {
//           getOrders: {
//             keyArgs: false,
//             read(existing = {}, { args }) {
//               const { date, status }: any = args
//               if (date?.from && date?.to) {
//                 return existing[`${date.from.split('T')[0]}-${date.to.split('T')[0]}`]
//               } else if (date?.from) {
//                 return existing[`${date.from.split('T')[0]}-`]
//               } else if (date?.to) {
//                 return existing[`-${date.to.split('T')[0]}`]
//               }

//               if (status) {
//                 return existing[status]
//               }
//               console.log(existing)
//               return undefined
//             },
//             merge(existing = {}, incoming, { args }) {
//               // console.log(existing)  
//               // console.log(incoming)
//               const { status, date }: any = args

//               let key: string
//               let merged: any
//               if (date?.from && date?.to) {
//                 key = `${date.from.split('T')[0]}-${date.to.split('T')[0]}`
//                 merged = existing[key]
//                   ? {
//                     items: [...existing[key].items, ...incoming.items],
//                     next_token: incoming.next_token
//                   }
//                   : {
//                     items: incoming.items,
//                     next_token: incoming.next_token
//                   }
//               } else if (date?.from) {
//                 key = `${date.from.split('T')[0]}-`
//                 merged = existing[key]
//                   ? {
//                     items: [...existing[key].items, ...incoming.items],
//                     next_token: incoming.next_token
//                   }
//                   : {
//                     items: incoming.items,
//                     next_token: incoming.next_token
//                   }
//               } else if (date?.to) {
//                 key = `-${date.to.split('T')[0]}`
//                 merged = existing[key]
//                   ? {
//                     items: [...existing[key].items, ...incoming.items],
//                     next_token: incoming.next_token
//                   }
//                   : {
//                     items: incoming.items,
//                     next_token: incoming.next_token
//                   }
//               }

//               if (status) {
//                 key = status
//                 merged = {
//                   items: incoming.items,
//                   next_token: incoming.next_token
//                 }
//               }
//               else {
//                 return
//               }



//               let newCache = { ...existing }
//               newCache[key] = merged

//               return newCache




//             }
//           }
//         }
//       }
//     }
//   }),
// })

interface CustomHeaders {
  [key: string]: string;
}

interface ApolloContextType {
  client: ApolloClient<any>;
  headers: CustomHeaders;
  setHeaders: (headers: CustomHeaders) => void;
}

const ApolloContext = createContext<ApolloContextType | undefined>(undefined);

interface ApiProviderProps {
  children: React.ReactNode;
}

export function useApi() {
  const context = useContext(ApolloContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
}



const cache = new InMemoryCache({
  possibleTypes: {
    IAccount: ["Clinic", "Pharmacy", "Unknown", "Individual"],
    IProduct: ["VirtualMedicinalProduct", "ActualMedicinalProduct"],
    IProductPack: ["VirtualMedicinalProductPack", "ActualMedicinalProductPack"],
    IConsultation: ["AsynchronousConsultation"],
    IMedicinalIngredient: ["Excipient", "ActivePharmaceuticalIngredient"],
    IPayment: ["PrescriptionPayment"]
  },
  typePolicies: {
    Query: {
      fields: {
        getOrders: {
          keyArgs: false,
          read(existing = {}, { args }) {
            const { date, status }: any = args
            if (date?.from && date?.to) {
              return existing[`${date.from.split('T')[0]}-${date.to.split('T')[0]}`]
            } else if (date?.from) {
              return existing[`${date.from.split('T')[0]}-`]
            } else if (date?.to) {
              return existing[`-${date.to.split('T')[0]}`]
            }

            if (status) {
              return existing[status]
            }
            console.log(existing)
            return undefined
          },
          merge(existing = {}, incoming, { args }) {
            // console.log(existing)  
            // console.log(incoming)
            const { status, date }: any = args

            let key: string
            let merged: any
            if (date?.from && date?.to) {
              key = `${date.from.split('T')[0]}-${date.to.split('T')[0]}`
              merged = existing[key]
                ? {
                  items: [...existing[key].items, ...incoming.items],
                  next_token: incoming.next_token
                }
                : {
                  items: incoming.items,
                  next_token: incoming.next_token
                }
            } else if (date?.from) {
              key = `${date.from.split('T')[0]}-`
              merged = existing[key]
                ? {
                  items: [...existing[key].items, ...incoming.items],
                  next_token: incoming.next_token
                }
                : {
                  items: incoming.items,
                  next_token: incoming.next_token
                }
            } else if (date?.to) {
              key = `-${date.to.split('T')[0]}`
              merged = existing[key]
                ? {
                  items: [...existing[key].items, ...incoming.items],
                  next_token: incoming.next_token
                }
                : {
                  items: incoming.items,
                  next_token: incoming.next_token
                }
            }

            if (status) {
              key = status
              merged = {
                items: incoming.items,
                next_token: incoming.next_token
              }
            }
            else {
              return
            }

            let newCache = { ...existing }
            newCache[key] = merged

            return newCache
          }
        }
      }
    }
  }
})

export function ApiProvider({ children }: ApiProviderProps) {
  const [headers, setHeaders] = useState<CustomHeaders>({});

  const { status } = useAuth()

  const client = useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.split(
        operation => operation.getContext().prescribe,
        ApolloLink.from([
          new RetryLink(),
          createAuthLink({ url: apiUrl('/prescribe'), region, auth }),
          new HttpLink({ uri: apiUrl('/prescribe'), headers })
        ]),
        ApolloLink.split(
          operation => operation.getContext().shipping,
          shippingLink,
          ApolloLink.split(
            operation => operation.getContext().patients,
            patientsLink,
            ApolloLink.split(
              operation => operation.getContext().products,
              productsLink,
              ApolloLink.split(
                operation => operation.getContext().payments,
                paymentsLink,
                ApolloLink.split(
                  operation => operation.getContext().orders,
                  ordersLink,
                  accountsLink
                )
              ),
            )
          )
        )
      ),
      headers,
      cache,
    })
  }, [headers])

  useEffect(() => {
    switch (status) {
      case AuthStatus.SignedOut:
        client.clearStore()
        break;
    }
  }, [status])

  const contextValue: ApolloContextType = {
    client,
    headers,
    setHeaders,
  };

  return (
    <ApolloProvider client={client}>
      <ApolloContext.Provider value={contextValue}>
        {children}
      </ApolloContext.Provider>
    </ApolloProvider>
  );
}

export default ApiProvider