import { Amplify, Hub } from 'aws-amplify'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use';
import { H } from '@highlight-run/next/client';


Amplify.configure({
  Auth: {
    region: process.env.NEXT_PUBLIC_REGION,
    userPoolId: process.env.NEXT_PUBLIC_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_CLIENT_ID,
  },
  ssr: true
})

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut
}

interface IAuthContext {
  user: {
    sub?: string,
    given_name?: string,
    family_name?: string,
    email?: string,
    email_verified?: boolean,
    'custom:account_id'?: string,
    groups?: string[]
  };
  getGroups: () => string[] | undefined,
  status: AuthStatus;
  signIn: (username: string, password: string) => Promise<void>;
  signUp: (username: string, password: string, attributes: { given_name: string, family_name: string }) => Promise<void>;
  resendConfirmationCode: (username: string) => Promise<void>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  signOut: () => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<void>;
  sendCode: (username: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  getCurrentUser: () => Promise<void>
}

const defaultState: IAuthContext = {
  user: {},
  status: AuthStatus.Loading,
  getGroups: () => undefined,
  signIn: () => Promise.resolve(),
  signUp: async () => { },
  resendConfirmationCode: async () => { },
  confirmSignUp: async () => { },
  signOut: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(),
  completeNewPassword: () => Promise.resolve(),
  sendCode: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  getCurrentUser: () => Promise.resolve(),
}

export const AuthContext = createContext<IAuthContext>(defaultState)

export interface ICognitoUser extends CognitoUser {
  attributes: { [key: string]: any }
}

export default function AuthProvider({ children }: { children: React.ReactNode }) {

  const [user, setUser] = useState<IAuthContext['user']>(defaultState.user)

  const [cognitoUser, setCognitoUser] = useState<ICognitoUser | null>(null)

  const [status, setStatus] = useState<IAuthContext['status']>(AuthStatus.Loading)

  const router = useRouter()

  const getCurrentUser: IAuthContext['getCurrentUser'] = async function () {
    try {
      const currentUser: ICognitoUser = await Auth.currentAuthenticatedUser()
      if (!currentUser.getSignInUserSession()) throw Error
      setCognitoUser(currentUser)
      setUser(currentUser.attributes)
      setStatus(AuthStatus.SignedIn)
      H.identify(currentUser.attributes.email, {
        ...currentUser.attributes,
      })
    } catch (err) {
      setStatus(AuthStatus.SignedOut)
      if (!['signup', 'signin', 'reset'].includes(router.pathname.split('/')[1])) {
        await router.push('/signin')
      }
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [router.pathname])


  useEffectOnce(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          setStatus(AuthStatus.SignedIn)
          router.push('/')
          H.identify(payload.data.attributes.email, {
            ...payload.data.attributes,
          })
          break
        case 'autoSignIn':
          setCognitoUser(payload.data)
          setUser(payload.data.attributes)
          setStatus(AuthStatus.SignedIn)
          router.push('/')
          H.identify(payload.data.attributes.email, {
            ...payload.data.attributes,
          })
          break
        case 'autoSignIn_failure':
          router.push('/signin')
          break
        case 'signOut':
          setCognitoUser(null)
          setStatus(AuthStatus.SignedOut)
          break
      }
    })
  })

  const refreshToken = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })
    setCognitoUser(cognitoUser)
    setUser(cognitoUser.attributes)
    H.identify(cognitoUser.attributes.email, {
      ...cognitoUser.attributes,
    })
  }

  const getGroups: () => string[] | undefined = () => cognitoUser?.getSignInUserSession()?.getAccessToken().payload['cognito:groups']

  const signIn: IAuthContext['signIn'] = async (username, password) => {
    const user: ICognitoUser = await Auth.signIn(username, password)
    setCognitoUser(user)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await router.push('/signup/new-password')
    }

    setUser(user.attributes)
  }

  const signUp: IAuthContext['signUp'] = async (username, password, { given_name, family_name }) => {
    const { user, userConfirmed, userSub } = await Auth.signUp({
      username,
      password,
      attributes: {
        given_name,
        family_name
      },
      autoSignIn: {
        enabled: true
      }
    })
    setCognitoUser(user as ICognitoUser)
  }

  const resendConfirmationCode: IAuthContext['resendConfirmationCode'] = async (username) => {
    await Auth.resendSignUp(username)
  }

  const confirmSignUp: IAuthContext['confirmSignUp'] = async (username, code) => {
    await Auth.confirmSignUp(username, code)
  }

  const signOut: IAuthContext['signOut'] = async () => {
    await Auth.signOut()
    setCognitoUser(null)
    setUser({})
    setStatus(AuthStatus.SignedOut)
    await router.push('/signin')
  }

  const forgotPassword: IAuthContext['forgotPassword'] = async (username) => {
    await Auth.forgotPassword(username)
  }

  const forgotPasswordSubmit: IAuthContext['forgotPasswordSubmit'] = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
  }

  const completeNewPassword: IAuthContext['completeNewPassword'] = async (newPassword: string) => {
    await Auth.completeNewPassword(cognitoUser, newPassword)
  }

  const sendCode: IAuthContext['sendCode'] = async (username: string) => {
    await Auth.resendSignUp(username)
  }

  const verifyCode: IAuthContext['verifyCode'] = async (username: string, code: string) => {
    await Auth.confirmSignUp(username, code)
  }

  return (
    <AuthContext.Provider value={{
      user,
      getGroups,
      status,
      signIn,
      signUp,
      resendConfirmationCode,
      confirmSignUp,
      signOut,
      forgotPassword,
      forgotPasswordSubmit,
      completeNewPassword,
      sendCode,
      verifyCode,
      refreshToken,
      getCurrentUser
    }}>
      {/* <PropelProvider> */}
      {children}
      {/* </PropelProvider> */}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const auth = useContext(AuthContext)
  if (!auth) {
    throw Error('not inside AuthProvider')
  }
  return auth
}