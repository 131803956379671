import React, { useContext, useEffect, useState } from "react";
import { User } from "../hooks/accounts";
import { Patient } from "../hooks/patients";
import { DosageInput, PrescriptionItem, SupplyInput } from "../hooks/prescribe";
import { Product } from "../hooks/products";


export interface IPrescribeContext {
  prescriber: User | undefined;
  setPrescriber: React.Dispatch<React.SetStateAction<User | undefined>>
  items: PrescriptionItem[];
  addProduct: (product: Product) => void;
  setSupply: (productId: string, supply: SupplyInput) => void;
  setDosage: (productId: string, dosage: DosageInput) => void;
  patient: Patient | undefined;
  setPatient: React.Dispatch<React.SetStateAction<Patient | undefined>>;
  removeProduct: (productId: string) => void;
  clear: () => void;
}

const initialState: IPrescribeContext = {
  prescriber: undefined,
  setPrescriber: () => { },
  items: [],
  addProduct: () => { },
  setSupply: () => { },
  setDosage: () => { },
  patient: undefined,
  setPatient: () => { },
  removeProduct: () => { },
  clear: () => { }
}

const PrescribeContext = React.createContext<IPrescribeContext>(initialState)

export const PrescribeProvider = ({ children }: any) => {
  const [prescriber, setPrescriber] = useState<User>()
  const [patient, setPatient] = useState<Patient>()
  const [items, setItems] = useState<PrescriptionItem[]>([])

  const addProduct = (product: Product) => {
    let index = items.findIndex((val) => val.product.id == product.id)
    if (index === -1) {
      items.push({
        product: product,
        dosage: { text: "" },
        supply: {
          quantity: { value: 0, unit: product.dose?.unit || product.dose?.size?.unit || 'unit' }
        }
      })
    } else {
      items[index].product = product
    }
    setItems([...items])
  }

  const removeProduct = (productId: string) => {
    const index = items.findIndex((item) => item.product.id == productId)
    if (index > -1) {
      items.splice(index, 1)
      setItems([...items])
    }
  }

  const setSupply = (productId: string, supply: SupplyInput) => {
    let index = items.findIndex((item) => item.product.id == productId)
    items[index].supply = supply
    setItems([...items])
  }

  const setDosage = (productId: string, dosage: DosageInput) => {
    let index = items.findIndex((item) => item.product.id == productId)
    items[index].dosage = dosage
    setItems([...items])
  }



  const clear = () => {
    setPrescriber(undefined)
    setPatient(undefined)
    setItems([])
  }

  return (
    <PrescribeContext.Provider value={{
      prescriber,
      setPrescriber,
      items,
      addProduct,
      removeProduct,
      setSupply,
      setDosage,
      setPatient,
      patient,
      clear
    }}>
      {children}
    </PrescribeContext.Provider>
  )
}

export const usePrescribe = () => {
  const ctx = useContext(PrescribeContext)
  if (ctx === undefined) {
    throw new Error('`usePrescribe must be within a `PrescribeProvider`')
  }
  return ctx
}